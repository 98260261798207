angular
    .module('annexaApp')
    .component('annexaNotificationAddresses', {
        templateUrl: './components/reg/annexa-notification-addresses/annexa-notification-addresses.html',
        controller: ['$http', 'Language', function ($http, Language) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();
            vm.notificationTypes = [];

            vm.selectNT = function($event, parent, index) {
                _.forEach(vm.notificationTypes[parent].notificationTypes, function (item, idx) {
                    if(index == idx) {
                        vm.notificationTypes[parent].notificationTypes[idx].selected = true;
                    } else {
                        vm.notificationTypes[parent].notificationTypes[idx].selected = false;
                    }
                })
            }

            var addressTypes = $linq(vm.addresses).distinctBy("x => x.addressType").select("x => x.addressType").toArray();

            if(addressTypes.length > 0) {
                var urlGetNotificationTypes = './api/reg/notification/third/addresses/notification_types/'

                _.forEach(addressTypes, function (item, index) {
                    urlGetNotificationTypes += item;

                    if(index != addressTypes.length - 1) {
                        urlGetNotificationTypes += ','
                    }
                });

                $http({
                    url: urlGetNotificationTypes,
                    method: 'GET'
                }).then(function (data) {
                    vm.notificationTypes = JSOG.decode(data.data);

                    _.forEach(vm.notificationTypes, function(ntItem, ntIndex) {
                        _.forEach(vm.notificationTypes[ntIndex].notificationTypes, function (item, index) {
                            vm.notificationTypes[ntIndex].notificationTypes[index].selected = index == 0 ? true : false;
                        })
                    })
                }).catch(function (error) {
                    vm.notificationTypes = [];
                });
            }
        }],
        bindings: {
            addresses: '=',
            notificationTypes: '='
        }
    })