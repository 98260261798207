angular
    .module('annexaApp')
    .component('annexaBoxDiligenceAudit',{
        templateUrl: './components/reg/annexa-box-diligence-audit/annexa-box-diligence-audit.html',
        require: {
            registerInputComponent: '^^annexaBoxObjectReg'
        },
        controller:['Language', '$rootScope', 'GlobalDataFactory', '$filter', '$scope', '$state', 
        function(Language, $rootScope, GlobalDataFactory, $filter, $scope, $state) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            
            
        	this.$onInit = function () {
            	if(!vm.boxTitle) {
                    vm.boxTitle = 'global.reg.diligence.auditBoxTitle';
                }
            	vm.emptyText = 'global.reg.diligence.auditBoxEmpty';
            	
            	$rootScope.$broadcast('registerEntryDiligenceAuditUpdated', { content: vm.content });
            }
        	
            $scope.$on('registerEntryDiligenceAuditUpdated', function (event, args) {
            	if(args.content && args.content.length > 0){
            		_.forEach(args.content, function(audit){
            			var auditAction = $linq(GlobalDataFactory.diligenceAuditActions).firstOrDefault({language1:audit.action, language2:audit.action,language3:audit.action},"x=> x.id == '"+audit.action+"'");
            			audit.actionLiteral = auditAction[vm.languageColumn];
            			audit.actionDate = ((audit.actionDate)?$filter('date')(new Date(audit.actionDate), 'dd/MM/yyyy HH:mm:ss'):'');
            			audit.actionAdditionalData = '';
            			if (audit.action == 'CREATED' || audit.action == 'REJECT' || audit.action == 'SENDTO' || audit.action == 'APPENDEDTO' || audit.action == 'RESPONSIBLE' || audit.action == 'INIT_DOSSIER' || audit.action == 'DUPLICATE_REGISTER') {
            				var obj = angular.fromJson(audit.additionalData);
            				if (obj) {
            					if (obj.number) {
            						audit.actionAdditionalData = obj.number;
            					} else if (obj.name) {
            						audit.actionAdditionalData = obj.name;
            					}
            				}
            			}
            		});
            	}
            });

        }],
        bindings: {
            boxTitle: '@?',
            content: '='
        }
    })