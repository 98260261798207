angular
    .module('annexaApp')
    .component('annexaBoxDiligenceComments',{
        templateUrl: './components/reg/annexa-box-diligence-comments/annexa-box-diligence-comments.html',
        require: {
            registerInputComponent: '^^annexaBoxObjectReg'
        },
        controller:['RegFactory', function(RegFactory) {
            var vm = this;

            vm.getUserName = function(user) {
                return user.name + ' ' + user.surename1 + (user.surename2 ? ' ' + user.surename2 : '');
            }

            vm.newComment = function () {
                RegFactory.createRegisterEntryDiligenceCommentModal(vm.registerInputComponent.registerEntryDiligence);
            }
        }],
        bindings: {
            comments: '='
        }
    })