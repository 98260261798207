angular
    .module('annexaApp')
    .component('annexaRegisterEntryDiligenceComments',{
        templateUrl: './components/reg/annexa-register-entry-diligence-comments/annexa-register-entry-diligence-comments.html',
        controller: ['$rootScope', 'RegFactory', function($rootScope, RegFactory) {
            var vm = this;

            vm.comment = undefined;

            vm.addComment = function () {
                var newComment = {
                    comments: vm.comment,
                    createdUser: { id: $rootScope.LoggedUser.id },
                    registerEntryDiligence: { id: vm.registerEntryDiligence.id }
                }

                RegFactory.newRegisterEntryDiligenceComment(newComment)
                    .then(function(data) {
                        if(!vm.registerEntryDiligence.registerEntryDiligenceComments) {
                            vm.registerEntryDiligence.registerEntryDiligenceComments = [];
                        }

                        data.createdUser = {
                            id: $rootScope.LoggedUser.id,
                            name: $rootScope.LoggedUser.name,
                            surename1: $rootScope.LoggedUser.surename1,
                            surename2: $rootScope.LoggedUser.surename2
                        }

                        vm.registerEntryDiligence.registerEntryDiligenceComments.push(data);
                        vm.comment = undefined;
                    }).catch(function (error) {
                        console.error(error);
                });
            }
        }],
        bindings: {
            registerEntryDiligence: '=',
            addComments: '=',
        }
    })